<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                meigara_details {{perf}}
                <DataTable :value="meigaradetails" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="i_date" header="レコード入力日" :sortable="true"></Column>
                    <Column field="meigara_cd" header="銘柄コード" filterField="meigara_cd" filterMatchMode="contains" ref="meigara_cd" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                        </template>
                    </Column>
                    <Column field="meigara_name_ja" header="銘柄名ja" :sortable="true"></Column>
                    <Column field="meigara_name_en" header="銘柄名en" :sortable="true"></Column>
                    <Column field="meigara_url" header="銘柄URL" :sortable="true"></Column>
                    <Column field="meigara_overview" header="銘柄概要" :sortable="true">
                    </Column>                     
                    <Column field="meigara_industry33_name" header="33業種区分" :sortable="true">
                    </Column>                    
                    <Column field="meigara_theme_list" header="テーマリスト" :sortable="true">
                    </Column>                    
                    <Column field="r_date" header="レコード更新日" :sortable="true"></Column>
                    <Column field="r_desc" header="レコード更新理由" :sortable="true"></Column>
                    <Column field="delisted" header="上場廃止区分" :sortable="true"></Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import MeigaradetailService from '@/service/MeigaradetailService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            meigaradetails: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'meigara_cd': {value: '', matchMode: 'contains'},
            },
            perf: null,
        }
    },
    meigaradetailService: null,
    created() {
        this.meigaradetailService = new MeigaradetailService();
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            setTimeout(() => {
                this.meigaradetailService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )})
                        .then(data => {
                            this.meigaradetails = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.loading = false;
                            this.perf = data.perf;
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        }   
    }
}
</script>

<style scoped>

</style>